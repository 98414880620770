import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import CTA from "../components/cta"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ServiceAreas from "../components/serviceAreas"
import HeroSection from "../components/heroSection"
import SubServicesAlt from "../components/subServicesAlt"
import Education from "../components/education"
import Testimonials from "../components/testimonials"

const GasLineServices = () => {
  const data = useStaticQuery(graphql`
    query GasLineImages {
      gasLines: file(relativePath: { eq: "gas-line-repair.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Gas Line Repair & Inspection"
        description={`Gas line leaks are a serious issue. Our professional, experienced team can handle your gas line repair safely and quickly so you can have peace of mind.`}
      />
      <HeroSection
        h1={`Gas Line Services`}
        h2={`Have an Experienced Master Plumber Fix Your Gas Lines`}
        h3={`Although there are many repairs homeowners can take care of themselves, gas lines should be worked on by experienced plumbers. If you suspect a gas leak, evacuate your home and call 911. We can repair your gas lines once your home and family is safe.`}
        p1={`We have vast experience with gas line maintenance over three decades of serving the North Houston area, which is why our service is safe, affordable, and thorough.`}
        heroImg={data.gasLines.childImageSharp.fluid}
        heroImgAltTag={`Gas Line Repair and Inspection`}
        buttonTitle={`All Drain Services`}
        path={`/gas-lines#services`}
      />
      <Education
        mainTitle={`Signs Your Gas Line Needs Repairing`}
        titleOne={`Strange Smells`}
        descriptionOne={`The scent of sulfur or "rotten eggs" is a strong indicator that your home has a gas leak.`}
        titleTwo={`Dead or Discolored Vegetation`}
        descriptionTwo={`If your gas line has a leak, you may notice that the grass and plants in your yard are dead/dying.`}
        titleThree={`Unusual Sounds`}
        descriptionThree={`Blowing noises or other irregular sounds coming from your gas appliances mean your gas system needs repair.`}
      />
      <SubServicesAlt
        titleOne={`General Repair`}
        descriptionOne={`Our team can resolve issues caused by an old system, aging pipes, or incorrect gas appliance installation`}
        titleTwo={`Leak Patching`}
        descriptionTwo={`Tree roots, gas buildups, or many other things can cause a gas leak. If you have a gas leak, it needs to be fixed immediately.`}
        titleThree={`Inspection and Prevention`}
        descriptionThree={`Preventative maintenance is the best way to avoid expensive repairs later on. Our professional team can pressure test and inspect your gas lines so you can have peace of mind.`}
      />
      {/* <Testimonials /> */}
      <ServiceAreas
        description={`gas lines`}
        subDescription={`gas line repairs and inspection`}
      />
      <CTA title={`Get a Free Quote to Fix Your Gas Lines Now`} />
    </Layout>
  )
}

export default GasLineServices
